/* You can add global styles to this file, and also import other style files */
@import "assets/styling/variables.scss";
html {
  box-sizing: border-box;
  max-height: 100vh;
}

body {
  margin: 0px !important;
  font-family: $fontFamily;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
}

.mat-icon {
  font-size: 24px;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
}
.api-left-navigation {
  float: left;
  width: 300px;
}

.api-content {
  float: left;
  width: calc(100vw - 300px);
}
.api-dashboard-footer {
  position: absolute;
  bottom: 0;
  width: calc(100vw - 300px);
}
.mat-form-field-appearance-outline:not(
    .mat-form-field-appearance-legacy
  )[fisStyle].mat-form-field-invalid
  .mat-input-element,
.mat-form-field-appearance-outline:not(
    .mat-form-field-appearance-legacy
  )[boxStyle].mat-form-field-invalid
  .mat-input-element,
*[fisStyle]
  .mat-form-field-appearance-outline:not(
    .mat-form-field-appearance-legacy
  ).mat-form-field-invalid
  .mat-input-element {
  border-color: $RedVariant1;
}
.mat-form-field input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: $fisFieldBorder;
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  background-color: $white;
  padding: 0 15px;
  color: $fisDarkTeal;
  font-family: $font;
  font-size: 14px;
}

.mat-form-field input:focus {
  border: $fisFieldFocusBorder;
}
.mat-form-field {
  &.mat-form-field-invalid
    .mat-input-element {
       border-color: $Error;
    }
}


.mat-form-field {
  @media screen and (max-width: 767px) {
    width: calc(100% - 19px);
  }
  width: calc(100% - 25px);
}

.mat-form-field-wrapper {
  padding: 0 !important;
  margin: 0 !important;
}

.error-icon-color.mat-icon {
  color: $Error !important;
  position: relative;
  right: -10px;
  top: -14px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: white !important;
}

.mat-form-field-appearance-outline {
  .mat-form-field-wrapper,
  .mat-form-field-flex,
  .mat-form-field-infix {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.mat-datepicker-popup .mat-datepicker-content {
  border: $fisLightBorder;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.mat-select-panel {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
  background-color: $white !important;
}

mat-option {
  height: 30px !important;
  font-family: Source Sans Pro, Arial !important;
  font-size: 14px !important;
  color: $fisDarkTeal !important;
}

.mat-datepicker-toggle .mat-button-ripple.mat-ripple {
  top: 0 !important;
  left: 0 !important;
}

.tooltip-transparent {
  background: transparent !important;
  height: 1rem !important;
  bottom: 3px !important;
  width: 1.5rem !important;
}

.mat-dialog-container {
  border-radius: 4px !important;
}

mat-icon {
  color: $fisDarkTeal !important;
}

.tooltip-transparent.mat-focus-indicator {
  border-color: $white !important;
  background-color: $white !important;
}

.filter-base-content {
  .down-arrow {
    top: -22px !important;
    left: -10px !important;
  }

  .mat-form-field {
    width: 100%;
    cursor: pointer;
  }

  .mat-form-field-wrapper {
    margin: 2px !important;
    padding: 0 !important;
  }

  .dialog-content-filter {
    height: 100vh;
    .mat-input-element {
      font-weight: 550;
    }
  }

  .mat-input-element {
    @media (max-width: 318px) {
      padding: 0 5px;
      font-size: 11px !important;
    }
    @media (min-width: 319px) and (max-width: 350px) {
      padding: 0 6px;
      font-size: 13px !important;
    }
    top: 6px;
    height: 3rem;
    padding: 0 15px;
  }

  .btn-clear.mat-focus-indicator {
    border-color: $GreenVariant2 !important;
    background-color: $white !important;
  }
}

.custom-modalbox {
  mat-dialog-container {
    margin: 0 !important;
    border-radius: 0px !important;
  }
}

.mcc-alert-popup {
  mat-dialog-container {
    @media (min-width: 600px) {
      width: 40vw !important;
      padding: 30px !important;

      .popup-content {
        margin-bottom: 5px !important;
      }

      .popup-footer .popup-button {
        width: 35% !important;
        margin-left: 30% !important;
      }
    }
  }
}

.filter-radio-group {
  .dialog-content-filter-date {
    height: 100vh;
  }

  .filter-title {
    text-align: center;
  }

  .radio-group {
    mat-radio-button {
      height: 2.5rem;
      h3 {
        font-weight: 600;
        margin: 2px;
      }
    }
  }

  .btn-clear.mat-focus-indicator {
    border-color: $GreenVariant2 !important;
    background-color: $white !important;
  }
}

.filter-checkbox {
  .dialog-content-filter-checkbox {
    height: 100vh;
  }

  .filter-title {
    text-align: center;
  }

  .btn-clear.mat-focus-indicator {
    border-color: $GreenVariant2 !important;
    background-color: $white !important;
  }

  .check-group {
    mat-checkbox {
      .mat-checkbox-layout {
        margin-bottom: 15px;
      }

      .mat-checkbox-label {
        font-weight: 600;
        white-space: normal;
        word-break: break-word;
        font-size: 16px;
      }
    }
  }
}

.filter-select-option.mat-form-field {
  height: 34px;
  background-color: white;
  font-size: 14px;
}

span#filterIconId {
  .filter-count-icon {
    font-size: 20px;
  }

  .filter-badge {
    span {
      background-color: #ffc845;
      top: -5px;
    }
  }
}
.ruf-statusbar .ruf-statusbar-title {
  overflow: visible !important;
}
.ruf-statusbar .ruf-statusbar-subtitle {
  text-overflow: unset !important;
  white-space: unset !important;
}
.mat-icon-currency-input.mat-icon {
  position: relative !important;
  left: calc(100% + 12px) !important;
  top: -30px !important;
  color: $Error !important;
}

.mat-form-field-curr-input.mat-form-field {
  input {
    padding-left: 15px;
  }

  .mat-form-field-infix {
    height: 40px !important;
  }
}

.mat-form-field-date.mat-form-field {
  .mat-input-element {
    max-width: calc(100% + 33px);
    width: calc(100% + 33px);
  }

  .mat-form-field-suffix {
    left: -4px;
  }

  .mat-icon-error-msg {
    position: relative;
    left: calc(100% + 45px);
    top: -30px;
    color: $Error !important;
  }

  .mat-icon-button {
    background: none;
  }

  .mat-form-field-infix {
    border-top: 0;
    height: 20px;
  }
}

.mat-datepicker-content-touch .mat-datepicker-content-container-with-actions {
  @media (max-width: 350px) {
    height: 125vw !important;
    padding: 10px !important;
  }
  @media (min-width: 351px) and (max-width: 1024px) {
    height: 109vw !important;
    padding: 10px !important;
  }
}

.mat-form-field-search-input.mat-form-field {
  .mat-input-element {
    border-radius: 4px;
  }
  .searchSymbol {
    position: relative;

    input {
      padding-right: 39px;
    }
  }

  .password-field {
    font-size: 10px !important;
  }

  .searchSymbol:after {
    content: url(../src/assets/icons/Search.svg);
    position: absolute;
    top: -14px;
    right: 10px;
    transform: scale(0.8);
    color: $fisDarkTeal;
  }

  .mat-form-field-infix {
    height: 40px !important;
  }

  .mat-icon-search-input {
    position: relative !important;
    left: calc(100% + 12px) !important;
    top: -30px !important;
    color: $Error !important;
  }
}

.mat-form-field-select.mat-form-field {
  mat-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: $fisFieldBorder;
    outline: none;
    border-radius: 4px;
    box-sizing: border-box;
    height: 40px;
    background-color: #fff;
    padding: 0 15px;
    color: #012834;
    font-family: Source Sans Pro, Arial;
    font-size: 14px;
    width: calc(100% - 2px);
    margin-left: 1px;
  }

  mat-select:focus {
    border: 1px solid #012834;
  }

  .mat-select-value {
    position: relative;
    top: 10px;
  }

  .mat-select-arrow-wrapper {
    position: relative;
    top: 9px;
  }

  .mat-form-field-infix {
    height: 40px !important;
  }

  .mat-icon-error-msg {
    position: relative !important;
    left: calc(100% + 12px) !important;
    top: -30px !important;
    color: $Error !important;
  }
}

.search-bar-main {
  .mat-form-field-wrapper {
    margin: 0px !important;
  }

  .mat-form-field {
    width: 100% !important;
  }

  .search-bar-item-1 {
    .mat-search-dropdown {
      margin-top: 12px !important;
      padding: 0 10px;
      box-sizing: border-box;
    }
  }

  .search-bar-item-2 {
    .mat-input-element {
      border: none !important;
      border-radius: 0 4px 4px 0 !important;
      margin: 0 !important;
      padding-right: 0px !important;
    }

    .mat-form-field-infix {
      height: 40px;
    }

    .hide-icon {
      display: none !important;
    }
  }
}

.profile-edit-backdrop,
.location-equipment-backdrop,
.equipment-view-backdrop,
.merchant-details-backdrop {
  background-color: black;
  opacity: 0.5 !important;
}

.profile-edit-panel {
  overflow: auto;
  max-width: 95vw !important;
  .mat-dialog-container {
    margin: 10px 0px;
    padding: 10px 0px;
  }
}

.location-equipment-panel {
  width: 56vw !important;
  max-height: 90vh !important;
  @media (max-width: 1024px) {
    height: auto;
    width: 100% !important;
    max-height: 98vh !important;
    max-width: 98vw !important;
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    width: 65vw !important;
  }
  .mat-dialog-container {
    margin: 10px 0px;
    padding: 10px 0px;
  }
}

.location-warning-popup {
  width: 35vw !important;
  @media (max-width: 500px) {
    width: 100% !important;
  }
  @media (min-width: 500px) and (max-width: 767px) {
    width: 56vw !important;
  }
}

.merchant-details-panel {
  width: 56vw !important;
  max-height: 90vh !important;
  @media (max-width: 767px) {
    height: auto;
    width: 100% !important;
    max-height: 98vh !important;
    max-width: 98vw !important;
  }
  @media (min-width: 767px) and (max-width: 1024px) {
    max-width: 80vw !important;
  }
  .mat-dialog-container {
    margin: 10px 0px;
  }
}

.equipment-view-panel {
  width: 65vw !important;
  max-height: 90vh !important;
  .mat-dialog-container {
    margin: 10px 0px;
    padding: 10px 0px;
  }
}

.ruf-dialog-container {
  margin: 0px !important;
  padding: 0px !important;
}

.nav-expansion-panel
  > .mat-expansion-panel-content
  > .mat-expansion-panel-body {
  padding: 0px;
}

.lead-list-tab-group > .mat-tab-body-wrapper {
  background-color: #f8f8f8;
}

.navbar-child-menu.mat-menu-panel {
  margin-left: 60px;
  margin-top: -50px;
}

.nav-panel-header {
  .mat-expansion-indicator:after {
    color: white;
  }
}

.nav-panel-header {
  .mat-expansion-indicator:after {
    color: white;
  }

  .mat-expansion-panel-body .mat-list-item-content {
    padding-left: 24px;
  }
}

.ruf-table {
  .mat-table[fisStyle] .mat-row.ruf-row-active-pale {
    background-color: rgb(241, 241, 241);
  }

  .mat-sort-header-container {
    justify-content: start !important;
  }

  .mat-paginator-container {
    justify-content: center;
  }

  .mat-paginator-page-size-select.mat-form-field-appearance-outline {
    width: 40px;
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 3px;
  }

  .mat-paginator[fisStyle] .mat-paginator-page-size-label,
  .mat-paginator[fisStyle] .mat-paginator-range-label,
  .mat-paginator[fisStyle] .mat-paginator-page-size-value {
    font-size: 12px;
  }

  .mat-select-value-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  .mat-icon-button[fisStyle]:not(.fis-icon-button-xs):not(
      .fis-icon-button-sm
    ):not(.fis-icon-button-md):not(.fis-icon-button-lg):not(
      .fis-icon-button-xl
    ),
  [fisStyle]
    .mat-icon-button:not(.fis-icon-button-xs):not(.fis-icon-button-sm):not(
      .fis-icon-button-md
    ):not(.fis-icon-button-lg):not(.fis-icon-button-small) {
    height: 36px;
    width: 36px;
    font-size: 19px;
  }

  .mat-table .mat-row .mat-cell {
    padding: 4px;
    font-size: 13px;
    border-bottom: 0.5px solid #dfdfdf;
    word-break: break-word;
  }

  .mat-table .mat-header-row .mat-header-cell {
    padding: 5px 3px;
    font-size: 13px;
    background-color: white;
    border-bottom: 2px solid black;
  }

  .mat-column-leadStatus {
    color: #015b7e;
  }

  .mat-row .mat-cell {
    padding: 7px !important;
    z-index: 0 !important;
  }

  .mat-header-row .mat-header-cell {
    padding: 3px !important;
    z-index: 0 !important;
  }
}

.search-location-table {
  .mat-cell.location-detail {
    border-bottom-color: white !important;
  }
  .mat-cell.add-border {
    border-bottom-color: #c1c1c1 !important;
  }
  .mat-cell.remove-border {
    border-bottom: white !important;
  }
}

.mid-ruf-table {
  .ruf-datatable-footer {
    display: none !important;
  }

  .mat-table .mat-row .mat-cell {
    border-bottom: none !important;
  }

  .mat-table[fisStyle] .mat-header-row .mat-header-cell {
    border-bottom-color: white;
  }
}

.mid-ruf-table.ruf-dynamic-datatable .mat-table {
  min-height: 10vh !important;
}

.status-tab-stepper {
  .mat-horizontal-stepper-header-container {
    height: 30px;
    background-color: #f8f8f8;

    .mat-step-header {
      .mat-step-icon {
        border: 4px solid white;
      }
    }

    .mat-step-header:nth-child(1) .mat-step-icon {
      background-color: #015b7e;
    }

    .mat-step-header:nth-child(3) .mat-step-icon {
      background-color: #015b7e;
    }

    .mat-step-header:nth-child(5) .mat-step-icon {
      background-color: #015b7e;
    }

    .mat-step-header:nth-child(7) .mat-step-icon {
      background-color: #05ac95;
    }

    .mat-step-header:nth-child(9) .mat-step-icon {
      background-color: #05ac42;
    }

    .mat-step-header:nth-child(11) .mat-step-icon {
      background-color: #50ff48;
    }

    .mat-step-header:nth-child(13) .mat-step-icon {
      background-color: #efefef;
    }

    .mat-step-header + .mat-stepper-horizontal-line {
      border-top-width: 10px;
      margin-right: -25px;
      min-width: 14.3%;
      border-image-slice: 1;
    }

    .mat-step-header:nth-child(1) + .mat-stepper-horizontal-line {
      border-image-source: linear-gradient(to right, #015b7e, #015b7e);
    }

    .mat-step-header:nth-child(3) + .mat-stepper-horizontal-line {
      border-image-source: linear-gradient(to right, #015b7e, #015b7e);
    }

    .mat-step-header:nth-child(5) + .mat-stepper-horizontal-line {
      border-image-source: linear-gradient(to right, #015b7e, #05ac95);
    }

    .mat-step-header:nth-child(7) + .mat-stepper-horizontal-line {
      border-image-source: linear-gradient(to right, #05ac95, #05ac42);
    }

    .mat-step-header:nth-child(9) + .mat-stepper-horizontal-line {
      border-image-source: linear-gradient(to right, #05ac42, #50ff48);
    }

    .mat-step-header:nth-child(11) + .mat-stepper-horizontal-line {
      border-image-source: none;
    }
  }

  .mat-horizontal-stepper-header {
    pointer-events: none;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
}

.status-table-data {
  .mat-table .mat-row {
    .mat-cell {
      padding: 0 !important;
    }
  }

  .mat-table .mat-row:nth-child(1) {
    .mat-cell {
      font-weight: bold;
      font-size: 12px;
    }
  }
}

.grid-status-tab-container {
  .status-tab-card-canvas {
    padding-bottom: 0;
  }
}

.btn-container {
  .clear-btn,
  .apply-btn {
    .mat-button-wrapper {
      font-size: 13px;
    }
  }

  .mat-form-field {
    width: calc(100% - 15px);
    padding: 4px !important;
    height: 35px !important;
  }

  .mat-form-field-infix {
    width: 100%;
  }

  .mat-form-field-label-wrapper {
    top: -28px;

    label {
      padding: 0 2px !important;
      color: $fisDarkTeal !important;
      font-weight: 600;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    top: 13px;
  }

  .mat-select {
    padding: 5px 0px;

    .mat-select-value {
      font-size: 12px;
    }
  }

  .mat-select-arrow {
    position: relative;
    top: -7px;
  }

  .mat-form-field-flex {
    height: 50px;
  }

  .mat-form-field-outline {
    min-height: 10px !important;
    height: 10px !important;
  }
}

.mat-select-panel {
  footer {
    border-top: 2px solid gray;
    height: 44px;
    padding-top: 10px;
    text-align: right;
    padding: 3px 5px;

    .savebtn {
      width: 100%;
      top: 4px;
    }
  }

  .custom-panel {
    max-height: 200px;
    overflow: auto;

    .select-all {
      margin: 8px 12px !important;
    }

    .mat-option-multiple {
      background-color: white !important;
    }

    .mat-checkbox-inner-container {
      height: 1rem !important;
      width: 1rem !important;
    }
  }
}

@media (min-width: 576px) {
  .profile-edit-panel {
    overflow: auto;
    max-width: 95vw !important;
    .mat-dialog-container {
      margin: 20px !important;
    }
  }
}

@media (max-width: 575px) {
  .company-head a {
    font-size: 12px !important;
  }
  .mat-paginator-outer-container {
    display: grid !important;
    align-items: center;
    justify-content: center;
    .mat-paginator-container{
      display: block !important;
    }
  }
  .mat-paginator-page-size-label {
    margin: unset !important;
    font-size: 14px !important;
  }
  .mat-paginator-range-label {
    margin: unset !important;
  }
}

@media (max-width: 767px) {
  .grid-container-list .lead-data-table .ruf-table {
    .mat-column-leadStatus {
      text-align: right;

      .mat-sort-header-container {
        justify-content: end !important;
      }
    }
  }

  .equipment-view-panel {
    height: auto;
    overflow: auto;
    width: 100% !important;
    max-height: 98vh !important;
    max-width: 98vw !important;
    .mat-dialog-container {
      margin: 0px !important;
      padding: 20px 10px 20px !important;
    }
  }
}

@media (min-width: 768px) {
  .header{
    position: fixed;
    top: 0;
    left: 0;
  } 
  .small-screen-block{
    display: none;
  }
  .bigger-screen-block{
    display: block;
  }
  .profile-edit-panel {
    overflow: auto;
    max-width: 95vw !important;
    .mat-dialog-container {
      margin: 20px !important;
      padding: 25px 50px !important;
    }
  }
  
}

@media (max-width: 911px) {
  .lead-data-table {
    .mat-paginator-page-size {
      display: none;
    }
  }
}

.search-box-section {
  .search-bar-main {
    box-shadow: none !important;
  }
}

.create-new-button {
  .dropdown {
    left: -45% !important;
  }
}

.ruf-dropdown-panel-container.fis-override {
  width: 120px;
  position: absolute;
  margin-left: -75px;
  margin-top: 10px;
  padding-bottom: 7px;
}

.status-table-data .ruf-card.fis-override .ruf-card-panel.mat-card {
  padding-bottom: 25px;
}

.account-selection-modalbox .mat-dialog-container {
  border-radius: 4px;
  max-height: 100vh;
  height: 530px;
  padding: 0;

  @media (max-width: 768px) {
    padding-top: 20px !important;
    margin: auto !important;
    height: 100vh !important;
  }
}

.account-selection-popover {
  text-align: center;
  padding-bottom: 8px !important;

  @media (max-width: 768px) {
    display: grid;
    height: 94vh;
    margin: auto;
  }
}

.account-selection-dropdown,
.account-selection-popover {
  ruf-dialog-content {
    display: block;
    margin: ruf-to-rem(0px) ruf-to-rem(-20px);
    max-height: 60vh;
    padding: ruf-to-rem(0px) ruf-to-rem(20px);
  }

  mat-selection-list {
    overflow: auto;
    .hide-checkbox .mat-pseudo-checkbox {
      display: none;
    }
    @media (max-width: 768px) {
      max-height: 400px;
    }
  }

  .custom-scroll-bar {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .custom-scroll-bar::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  .custom-scroll-bar::-webkit-scrollbar-track {
    background-color: white !important;
  }

  .custom-scroll-bar::-webkit-scrollbar-thumb {
    background: #eeeeee !important;
    border-radius: 10px;
  }

  .account-list {
    color: $BlueVariant2 !important;
    text-align: left;
    max-width: 100%;
    padding: 0 10px !important;
  }

  .mat-list-base .mat-list-option {
    line-height: 23px !important;
    font-size: 16px !important;
    @media (max-width: 768px) {
      height: auto !important;
      font-size: 14px !important;
      margin-bottom: 10px !important;
    }
  }

  .mat-list-option[aria-selected="true"] {
    font-weight: 600;
  }

  .mat-icon {
    margin: auto;
    @media (max-width: 768px) {
      padding-right: 20px !important;
    }
  }
}

.mat-menu-panel {
  max-width: 600px !important;
}

.grid-wrapper .progress-bar-custom-quote {
  .timeline-container {
    @media (max-width: 1140px) {
      margin-top: 0px;
    }
    margin-bottom: -30px;
    margin-top: -10px;
  }

  .timeline-title {
    display: none;
  }
}

.grid-wrapper-merch-info .mat-checkbox .mat-checkbox-layout {
  white-space: normal !important;
}

.grid-wrapper-business-details,
.quote-options-grid-container,
.grid-wrapper-summary,
.location-equip-grid-container,
.equipment-card-container,
.banking-card-container,
.account-details-card-container,
.header-container {
  .back-button .mat-raised-button,
  .location-active .mat-raised-button,
  .equipment-back-button .mat-raised-button,
  .banking-back-button .mat-raised-button,
  .account-details-back-button .mat-raised-button,
  .navigate-addloc .mat-raised-button {
    background-color: transparent !important;
    &:hover {
      background-color: $fisGreen !important;
    }
  }
}

.location-card-container .check-group-no-pad {
  display: grid !important;
  grid-template-columns: repeat(2, 5rem);
}

.failure-alert .ruf-statusbar .ruf-statusbar-title,
.success-alert .ruf-statusbar .ruf-statusbar-title {
  white-space: normal !important;
}

.location-equip-grid-container .ruf-card:not(.ruf-card-rtl) .ruf-card-header,
.merchant-details-container .ruf-card:not(.ruf-card-rtl) .ruf-card-header {
  min-height: 0;
}

.location-equip-grid-container .ruf-card.fis-override .ruf-card-header {
  height: 1rem;
}

.location-equip-grid-container .ruf-card.fis-override .ruf-card-panel.mat-card {
  border-radius: 0.4rem;
  border-width: 0.12rem;
}

.location-equip-grid-container .ruf-card-panel.mat-card {
  padding-bottom: 0.5rem;
}

.location-equip-grid-container .ruf-card-panel.mat-card .mat-card-content {
  margin-right: 0.5rem;
}

.location-equip-grid-container .card-error-icon.mat-icon {
  top: 2px;
  right: 0px;
}

.location-equip-grid-container
  .ruf-card.error-border.fis-override
  .ruf-card-panel.mat-card {
  border-left-width: 0.2rem !important;
  border-left-color: #b2162b !important;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.bottom-margin
  .ruf-statusbar-wrapper
  .ruf-statusbar-header
  .ruf-statusbar-expand {
  height: 0px !important;
}
.ruf-statusbar .mat-icon-button.ruf-statusbar-expand {
  display: none !important;
}
.locations-panel .ruf-dialog-header .ruf-dialog-header-bar .mat-icon-button {
  display: none;
}

.grid-wrapper-summary .sum-table,
.equip-table-section,
.equip-view-table,
.equip-view-table-section {
  .mat-paginator-container {
    display: none !important;
  }

  .ruf-dynamic-datatable .ruf-datatable-no-data-cell {
    display: none !important;
  }

  .mat-table {
    .mat-header-row {
      .mat-header-cell {
        border-bottom-width: 2px !important;
        border-bottom-color: $fisDarkTeal !important;
        padding: 0.6rem !important;
        font-size: 14px !important;
      }
    }

    .mat-row {
      .mat-cell {
        font-size: 14px !important;
        padding: 0.6rem !important;
      }
    }
  }
}

.grid-wrapper-summary .sum-table {
  .mat-table thead th {
    background: white !important;
  }

  .mat-table {
    .mat-header-row {
      .mat-header-cell.mat-column-equipCost {
        text-align: right;
      }
    }

    .mat-row {
      .mat-cell {
        line-height: 1.3;
      }

      .mat-cell.mat-column-location p:first-child,
      .mat-cell.mat-column-equipments p:last-child,
      .mat-cell.mat-column-equipCost p:last-child {
        font-weight: bold;
      }

      .mat-cell.mat-column-equipCost {
        text-align: right;
      }
    }
  }
}

.equip-table-section {
  .mat-table thead th {
    background-color: #f8f8f8 !important;
  }

  .mat-table {
    .mat-row {
      .mat-cell p:first-child {
        font-weight: bold;
      }

      .mat-cell.mat-column-view {
        text-align: right;
      }
    }
  }
}

.equip-view-table {
  .mat-table thead th {
    background-color: white !important;
  }
}

.card-container-equip {
  .mat-table thead th {
    background-color: white !important;
  }

  .mat-table {
    .mat-header-row {
      .mat-header-cell {
        padding: 0.6rem 0 !important;
      }

      .mat-header-cell.mat-column-quantity {
        text-align: right;
      }
    }

    .mat-row {
      .mat-cell {
        padding: 0.6rem 0 !important;
      }

      .mat-cell.mat-column-quantity {
        text-align: right;
      }
    }
  }

  .location-form-container .icon.mat-icon {
    color: $fisGreen;
  }
}

.banking-card-container .guide-block .guide-error-row {
  min-height: 0 !important;
}

.banking-card-container .previous-account .label-row {
  height: 0px !important;
}

.mat-input-element[type="password"] {
  font-size: 1.1rem !important;
}

input::-ms-clear,
::-ms-reveal {
  display: none !important;
}

.cdk-overlay-container
  .mat-menu-panel.fis-style
  .mat-menu-content
  .mat-menu-item {
  text-transform: none !important;
}

.location-equip-grid-container
  .mat-raised-button[fisStyle].mat-primary:not([disabled]).cdk-program-focused {
  box-shadow: none !important;
}

demo-boxed-select {
  :host {
    display: block;
  }

  .comparison {
    display: flex;
  }

  .settings {
    margin-bottom: 20px;
    display: flex;
  }

  .mat-mdc-card {
    flex: 1 1 33%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .mat-mdc-card > :first-child {
    border-bottom: 1px solid #ddd;
  }

  .mat-mdc-card:first-of-type {
    margin-left: 0;
  }

  .demo-basic {
    padding: 0;
  }

  .demo-basic .mat-mdc-card-content {
    padding: 25px;
  }

  .demo-full-width {
    width: 100%;
  }

  .demo-width-350 {
    width: 350px;
  }

  .demo-card {
    margin: 16px 0;
  }

  .demo-text-align-end {
    text-align: end;
  }

  .demo-textarea {
    resize: none;
    border: none;
    overflow: auto;
    padding: 0;
    background: lightblue;
  }

  mat-toolbar {
    background: rgba(0, 0, 0, 0.1);
    font-size: 16px;
  }

  .demo-card {
    &.demo-basic {
      .mat-toolbar {
        margin-bottom: 15px;
      }
    }
  }
}

/** New Style 05-12-2023 **/

.api-basic-card mat-card-title {
  font: $H1Font !important;
}
.mat-card {
  padding-bottom: 17px !important;
}
.mat-card-content {
  .card-content {
    margin-top: 10px !important;
    margin-bottom: 10px;
  }
}
.ruf-app-canvas {
  height: calc(100vh - 200px) !important;
  background: $white !important;
}
.content-header {
  grid-area: content-header;
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: visible !important;
  margin-bottom: 20px;
}
.main-header {
  color: #191919;
  font-size: 30px;
  line-height: 30px;
  font-weight: 600; 
}

.contact-form,
.user-form {
  .button-container button {
    margin-right: 20px !important;
  }
}

.api-contact-des textarea {
  background: $white;
  padding: 0 5px !important;
  &::placeholder {
    font-size: 12px !important;
    right: 6px !important;
    position: absolute !important;
    bottom: 2px !important;
  }
}

.custom-hint {
  float: left;
  width: 100%;
}

/* Common Style */
.api-left-align .mat-grid-tile-content {
  justify-content: left !important;
}

/* Helper classes */
.pd-0 {
  padding: 0 !important;
}
.mt-15 {
  margin-top: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mr-10 {
  margin-right: 10px !important;
}
.mb-20{
  margin-bottom: 20px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.width250 {
  width: 250px;
}
.width100{
  width: 100%;
}
.height-auto {
  height: auto Im !important;
}

.mat-form-field-hint-spacer {
  display: none !important;
}

.mat-form-field-subscript-wrapper {
  position: relative !important;
}

.mat-form-field-label-wrapper {
  margin-top: -10px !important;
}
.mat-form-field-label {
  font: $font18Source !important;
  transform: translateY(-1.34375em) scale(0.75) !important;
  color: $fieldLabel !important;
}
.mat-placeholder-required {
  color: $RedVariant1 !important;
}

.contact-info,
.user-info {
  h3 {
    margin: 0;
    color: $fisDarkTeal;
    font: $FormLabelFontH3;
    margin: 15px 0;
  }

  h4 {
    font: $FormLabelFontH4;
    margin: 0;
    color: $fieldLabel;
    span {
      color: $RedVariant1;
    }
  }
}
.contact-us-box { 
  margin-top: 15px;
  .mat-grid-tile {
    overflow: visible;
  }
}

.contact-us-text-area {
  height: 100px;
  resize: none !important;
  margin-top: 0px !important;
  border: $fisFieldBorder !important;
  border-radius: 4px;
}

.contact-us .mat-form-field-label-wrapper::after {
  content: "Optional";
  width: 100px;
  right: 0;
  position: absolute;
  text-align: right;
  font: $font14Source !important;
  color: $fieldLabel !important;
}
.api-file-upload {
  padding-left: 15px;
  .mat-hint {
    float: left;
    width: 100%;
    margin-top: 10px !important;
    font: $FormLabelFont;
  }
}

.mat-placeholder-required {
  float: left;
  font-size: 25px;
  margin-right: 2px;
}

.example-dropdown-basic-3 .person_link {
  color: $fisDarkTeal !important;
  font: $FormLabelFontH3;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  margin-top: 10px;
  .mat-icon {
    margin-right: 7px;
  }
}

.demo-table-card tr th:first-child div {
  visibility: hidden;
}

// .mat-table[fisStyle] .demo-detail-row .mat-cell {
//   padding: 0 !important;
//   border: none;
// }

// .demo-expanded .demo-detail-row .mat-cell{
//   padding: 0.9375rem !important;
// }
registered-users table, invited-users table, my-api-keys table, product-details-ext table, product-details-ext .commondata-access-info table, companies-details table {
  width: 100% !important;
  display: table;
  .view-more-btn {
    display: none !important;
    max-width: unset !important;
  }
}
.tabs-access {
  width: auto;
  padding: 20px 0 0 30px;
  height: 235px;
  overflow-y: scroll;
  display: block;
  th {
    text-align: left;
    font-size: 18px;
    border-bottom: 1px solid $apiBgBlack;
    padding: 0;
    min-width: 160px;
  }
  td {
    padding: 8px 8px 8px 0;
    border-bottom: 1px solid #c3c3c3;
    word-break: break-word;
  }
}

 companies-details table .sp {
  display: block;
  width: 160px;
  font-size: 14px;
 }
 
 
.demo-expanded .fis-icon-chevron {
  transition: 100ms ease-in-out;
  transform: rotate(180deg);
}
.demo-expanded-90 .fis-icon-chevron {
  transition: 100ms ease-in-out;
  transform: rotate(270deg);
} 

.fis-icon-chevron {
  transition: 100ms ease-in-out;
  transform: rotate(360deg);
}

.summary-table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  border-spacing: 2px;
  border-color: gray;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.67;
  word-break: break-word;
  color: $fisDarkTeal;
  font: $FormLabelFont12400;
}

.summary-tab-stepper {
  grid-template-columns: repeat(1,1fr);
  max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-gap: 0rem;
    padding: 24px;
    tr {
      vertical-align: middle;
      th {
        font-size: 13px;
        vertical-align: top;
        width: 135px;
        text-align: left;
        line-height: 1.67;
        word-break: break-all;
    }
     td {
      font-weight: 400;
      font-size: 13px;
      vertical-align: top;
      padding: 0px 2px 5px;
      text-align: left;
      line-height: 1.67;
      word-break: break-all;
    }
  }
}
.tablecolumnFit {
  height: -moz-fit-content;
  height: fit-content;
}

.demo-table-card {
  padding: 0 !important;
  border-color: transparent !important;
  margin-top: 30px;
  .mat-header-row,
  .mat-header-row:hover {
    background: $white !important;
    .mat-header-cell {
      padding-left: 4px !important;
    }
  }
  .mat-table[fisStyle]
    .mat-header-row
    .mat-header-cell:hover:not(.mat-table-sticky).mat-sort-header {
    background-color: $white !important;
  }
}
.demo-detail-row .mat-tab-body-wrapper {
  background-color: $apiLighterGray;
}

.demo-expanded {
  background-color: $apiOffWhite !important;
}
.mat-row .mat-cell {
  padding: 4px !important;
  z-index: 0 !important;
}

.demo-table-card .mat-column-expandedDetail {
  padding: 0 !important;
}
.mat-input-element:disabled {
  background-color: $apiOffWhite;
  color: $apiTxtDisable !important;
}
.filter-search-container-api {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 90%; 
  .mat-form-field-label-wrapper {
    display: block !important;
  }
  .mat-card-content{
    width: 98%;
    float: left;
    padding-left: 8px;
    overflow: initial !important;
  } 
  .search-box-input-filter {
    width: auto !important;
    margin-right: 10px;
    float: left;  
  } 
  .mat-card-content .card-content { 
    width: 100% !important;
    float: left; 
    display: flex;
  }
}
.filter-search-container {
  margin-bottom: 20px;
  margin-top: 20px;
  width: inherit; 
  .fis-icon-search {
    font-size: 18px !important;
    margin-top: 4px;
    cursor: pointer;
  }
  .mat-card-header {
    display: none;
  }
  .ruf-elevation-z1 {
    border: none !important;
    box-shadow: none !important;
  }
  .mat-card {
    padding-bottom: 7px !important;
  }
  .mat-form-field {
    float: left;
    flex-grow: 1;
  }
  .mat-form-field-label-wrapper {
    display: none;
  }
  .fis-icon-search {
    float: right;
    position: relative;
    top: -32px;
    right: 6px;
  }
  .mat-form-field-infix {
    padding: 0 !important;
    height: 39px;
    margin-right: -1px;
  }
  .select-box-api-filter {
    .mat-form-field-infix {
      border-radius: 5px;
    }
  }
  .search-box-input-filter {
    width: 30%;
    margin-right: 10px;
    .mat-form-field-label-wrapper {
      display: block;
      .mat-form-field-label {
        top: -12px;
      }
    }
    .mat-form-field-infix {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .inner-select-box {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1019607843);
  }
  .mat-form-field-underline {
    display: none !important;
  }
  // .mat-select {
  //   padding-left: 10px;
  // }
  .ruf-card .ruf-card-content {
    margin: 0;
  }
  // .mat-form-field-flex {
  //   box-shadow: 0 4px 8px #0000001a;
  // }
  .mat-card-content .card-content {
    margin-top: 20px !important;
    margin-bottom: 10px;
    width: calc(100% - 186px);
  }
}

.select-box-api-filter {
  width: 167px;
  .mat-select-arrow-wrapper {
    margin-right: 15px;
  }
  .mat-form-field-infix {
    background-color: $white;
  }
}

.api-key-filter {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  margin-left: 20px;
  margin-top: 6px;
  cursor: pointer;
  mat-icon {
    font-size: 20px;
  }
  .mat-icon-button {
    -webkit-tap-highlight-color: none !important;
  }
  .mat-button-ripple {
    display: none;
  }
  .mat-icon-button[fisStyle]:not([disabled]):hover {
    background: none !important;
  }
}
.filter-expanded-view-api {
  float: left;
  width: 47%;
  margin-top: -24px;
  height:auto; 
  .expiry-drop-down {
    background: $white;
    padding: 2px 4px;
    width: auto;
    margin-right: 20px;
    margin-left: -10px;
  }
  .m-r-0{
    margin-right: 0 !important;
  }
  .ruf-card-action-bar {
    display: inline !important;
  }
  .ruf-card-goto-links {
    max-height: 55px;
  }
}
.filter-expanded-view {
  float: left;
  width: 100%;
  margin-top: 5px;
  height:76px;
  padding-left: 5px;
  margin-left: -12px;
  padding-right: 20px;
  .expiry-drop-down {
    background: $white;
    padding: 2px 4px;
  }
  .ruf-card-action-bar {
    display: inline !important;
  }
  .ruf-card-goto-links {
    max-height: 55px;
  }
}
.filter-select-label { 
  line-height: 18px;
  color: $fieldLabel !important;
  font: $font14Source !important;
}
.filter-expanded-view-api {
  .mat-form-field-infix  mat-select {
    margin-top: -3px !important;
  }
}
.filter-expanded-view, .filter-expanded-view-api {
  .mat-form-field-appearance-outline .mat-form-field-outline-start{
    border: none;
  }
  .mat-form-field-flex {
    box-shadow: none !important;
  }
  .mat-form-field-infix {
    border: none !important;
    mat-select {
      padding: 3px 5px 10px;
      border-radius: 4px;
      margin-top: 2px;
      height: 26px;
      border:$fisFieldBorder;
    }

    .mat-select-arrow-wrapper {
      margin-top: -2px;
    }
  }
}
.api-keys-info h4 {
  font-size: 13px;
  color: #012834;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-weight: 600;
  margin: 17px 0 2px;
}
.card-example-canvas {
  .mat-badge-content {
    background-color: $YellowVariant1;
  }
}

.apply-box-border .ruf-app-canvas mat-card {
  .ruf-card-content {
    padding: 0px 0px 15px 0;
  }

  .filter-search-container .mat-form-field input,
  .filter-search-container .inner-select-box,
  .filter-search-container-api .mat-form-field input,
  .filter-search-container-api .inner-select-box{
    box-shadow: none !important;
  }
  .filter-search-container .mat-form-field,
  .filter-search-container-api .mat-form-field {
    background: $white;
  }
}

.error-msg-color {
  color: $RedVariant1 !important;
  font-size: 12px;
  font-family: Source Sans Pro, Arial, sans-serif;
  margin-top: 7px;
}

.pill-container {
  background: $apiDarkestTeal;
  color: $white;
  display: table;
  padding: 2px 4px 4px 18px;
  max-width: 50%;
  border-radius: 30px;
  min-width: 150px;
  margin-bottom: 25px;
  .pill-inside {
    display: flex;
    align-items: center;
    justify-content: center;
    font: $FormLabelFont14400;
    span {
      margin-right: 5px;
    }
  }
  button {
    background: transparent;
    border: none;
    margin-top: 4px;
    cursor: pointer;
    .mat-icon {
      color: $white !important;
      font-size: 17px;
    }
  }
}
.error-block {
  width: 100%;
  display: flex;
 
  mat-error {
    color: $ErrorValidation !important;
  }
}
.filterbox-close {
  float: right;
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
}
.noDataIcon {
  float: left;
  text-align: center;
  width: 100% !important;
}
.blankData {
  height: 250px;
  width: 100% !important;
  text-align: center;
  font: $FormLabelFont14400;
  border-bottom: 1px solid #c1c1c1;
  font-style: italic;
}
// .blankDataNew {
//   height: 140px;
//   width: 100% !important;
//   text-align: center;
//   font: $TableNoDataFont16700 !important;
//   color: $fisDarkTeal !important;
//   p {
//     font: $FormLabelFont;
//   }
// }
.product-info {
  mat-tab-group {
    .blankData {
      height: 250px;
      text-align: center;
      font: $FormLabelFont14400;
      border-bottom: 1px solid #c1c1c1;
      font-style: italic;
      vertical-align: top;
      padding-top: 20px;
    }
  }
}
.sessionExpiredMessage1 {
  font: $font16Source;
}
.sessionExpiredMessage {
  font: $font16Source;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  text-align: center;
  padding: 40px 0;
}
timeout-warning .modal-content {
  min-height: 245px;
}

.api-mask-icon {
  position: relative;
  align-items: inherit;
  display: inline-flex;
  mat-icon {
    font-size: 19px !important;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
  }
  button {
    border: none;
    background: unset;
    cursor: pointer;
  }
}

.home-page-card .card-example-canvas {
  height: auto !important;
  padding-bottom: 0 !important;
}

// .api-keys-filter-canvas {
//   .mat-card-content {
//     .card-content {
//       margin-top: 0px !important;
//     }
//   }
// }
body .api-basic-card.ruf-elevation-z1 {
  box-shadow: none !important;
}

.initial_loading {
  background-color: $white;
  z-index: 99999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  .login_loading {
    top: 50%;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .login_loading {
    div {
      width: 96px;
      height: 96px;
      border-width: 6px !important;
      display: inline-block;
      float: none;
      border: 2px solid currentColor;
      background: 0 0;
      border-bottom-color: transparent;
      border-radius: 100%;
      -webkit-animation: 0.75s linear infinite ball-clip-rotate;
      animation: 0.75s linear infinite ball-clip-rotate;
    }
  }
  .la-ball-clip-rotate {
    display: block;
    font-size: 0;
    color: #fff;
    width: 32px;
    height: 32px;
  }
  .ruf-statusbar .mat-icon-button.ruf-statusbar-expand {
    display: none !important;
  }
}
.api_loading {
  background-color: rgba(51, 51,51, 0.8);
  z-index: 100000;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  .login_loading {
    top: 50%;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .login_loading {
    div {
      width: 96px;
      height: 96px;
      border-width: 6px !important;
      display: inline-block;
      float: none;
      border: 2px solid currentColor;
      background: 0 0;
      border-bottom-color: transparent;
      border-radius: 100%;
      -webkit-animation: 0.75s linear infinite ball-clip-rotate;
      animation: 0.75s linear infinite ball-clip-rotate;
    }
  }
  .la-ball-clip-rotate {
    display: block;
    font-size: 0;
    color: #fff;
    width: 32px;
    height: 32px;
  }
  .ruf-statusbar .mat-icon-button.ruf-statusbar-expand {
    display: none !important;
  }
}

#customModal {
  padding-top: 0;
  overflow: hidden;
  width: 100vw;
  display: block;
  .modal-content {
    width: 100%;
    height: inherit;
    color: $apiTxtDisable;
    h1 {
      font: $HeroFont;
      font-size: 28px !important;
    }
    h4 {
      font: $H2Font;
    }
  }
}

.primarybtn {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  background-color: #49498C;
  color: #012834;
  font-family: SourceSansProBold;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  border: none;
}

.primarybtn:hover {
  height: 36px;
  width: 72px;
  border: 3px solid #49498C;
  border-radius: 24px;
  background: none;
  color: #012834;
  font-family: SourceSansProBold;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.secondarybtn {
  height: 36px;
  width: 72px;
  border: 3px solid #49498C;
  border-radius: 24px;
  background: none;
  color: #012834;
  font-family: SourceSansProBold;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}

.secondarybtn:hover {
  height: 36px;
  width: 72px;
  border-radius: 24px;
  background-color: #49498C;
  color: #012834;
  font-family: SourceSansProBold;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
  .cancelPopup {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  align-content: center;
  .modal-content {
    background-color: #fefefe;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #888;
    width: 22%;
    border-radius: 6px;
    top: 25%;
    position: relative;
    h2 {
      font: $HeroFont;
      margin-top: 0;
    }
    p {
      font: $font14Source;
    }
  }
}
.lookupModal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 55%;
    border-radius: 6px;
    h2 {
      font: $HeroFont;
      margin-top: 0;
    }
    p {
      font: $font14Source;
    }
    mat-card {
      .mat-form-field-infix {
        padding: unset !important;
        border-top: unset !important;
      }
      table {
        width: 100%;
      }
    }
    .demo-table-card {
      max-height: 250px;
      min-height: 100px;
      overflow: auto;
      scrollbar-width: thin;
    }
    .demo-table-card tr th:first-child div {
      visibility: unset;
    }
    .mat-paginator-container {
      justify-content: center !important;
    }
    .filter-search-container {
      width: 100%;
    }
  }
}

.confirm-button-box {
  float: right;
  button {
    margin-left: 12px;
  }
}
.mat-date-range-input-separator {
  display: none;
}

.hideArrow .mat-sort-header-arrow {
  opacity: 0 !important;
}
// .showArrow .mat-sort-header-arrow {
//   opacity: 1 !important;
// }

.mat-header-row .mat-header-cell.mat-sort-header.mat-sort-header-disabled {
  cursor: pointer !important;
  .mat-sort-header-container {
    cursor: pointer !important;
  }
}
// product-details, app-api-keys  {
//   .demo-table-card tr th:first-child div {
//     visibility: unset;
//   }
// }
companies-details {
  .demo-table-card {
    margin-top: unset !important;
  }
  .commondata-access-info {
    .pci-div{
      display: flex;
      table {
        width: 25% !important;
      }
    }
    
  }

  .basic-info, .commondata-access-info, .merchant-hierarchy, .contacts-info, .product-info, .api-key-info {
    margin-top: 18px; 
    table {
      tr {        
        height: auto;
        min-height: 30px !important;
        width: 100%;
      }
      th {
        text-align: left;
        padding: 0.3125rem 0.9375rem 0.3125rem 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
      td {
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        word-break: break-all;
      }
      td.demo-element-row {
        padding: 7px 7px 7px 0 !important;
      }
    }
    
    .mat-table[fisStyle] .mat-row .mat-cell {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      max-width: 240px !important;
      word-break: break-word;
    }
    .demo-table-card {
      .mat-header-row,
      .mat-header-row:hover {
        .mat-header-cell {
          padding-left: 0px !important;
        }
      }
    }
  }
  .basic-info {
    table {
      td {
        padding-bottom: 18px;
        border-bottom: 1px solid #D7D7D7;
      }
    }
  }
  .merchant-hierarchy {
    table {
      th {
        border-bottom: 2px solid #c1c1c1;
      }
      td {
        border-bottom: 1px solid #c1c1c1;
      }
    }
  }
  .contacts-info {
    margin-bottom: 30px;
    table {
      th {
        border-bottom: 2px solid #c1c1c1;
     }
   }
  }
}
product-details-ext {
  .demo-table-card {
    margin-top: unset !important;
  }
  .commondata-access-info {
    .pci-div{
      display: flex;
    }
    table {
      width: 50%;
    }
  }
  .basic-info, .commondata-access-info, .merchant-hierarchy, .contacts-info, .product-info, .api-key-info {
 
    table {
      tr {        
        height: auto;
        min-height: 30px;
      }
      th {
        text-align: left;
        padding: 0.3125rem 0.9375rem 0.3125rem 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
      td {
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        word-break: break-all;
      }
      td.demo-element-row {
        padding: 7px 7px 7px 0 !important;
      }
    }
    .mat-table[fisStyle] .mat-row .mat-cell {
      font-size: 12px;
      font-weight: 400;
      line-height: 1.67;
      max-width: 600px !important;
      word-break: break-word;
    }
  }
  .merchant-hierarchy {
    table {
      th {
        border-bottom: 2px solid #c1c1c1;
      }
      td {
        border-bottom: 1px solid #c1c1c1;
      }
    }
  }
  .contacts-info {
    margin-bottom: 30px;
    table {
      th {
        border-bottom: 2px solid #c1c1c1;
     }
   }
  }
}
provision-app-api-key {
  // .api-prov-product-section {
    .table-div {
      max-height: 330px;
      min-height: 100px;
      overflow: auto;
      scrollbar-width: thin;
    }
    table {
      tr {
        height: auto;
        min-height: 30px;
      }
      th {
        text-align: left;
        padding: 0.3125rem 0.9375rem 0.3125rem 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
      td {
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        word-break: break-all;
      }
      td.demo-element-row {
        padding: 7px 7px 7px 0 !important;
      }
    }
  // }
}
@media (min-width: 768px) {
  .nav-and-content {
    background: linear-gradient(to right, $fisDarkTeal 300px, white 0);
  }
}

@media (max-width: 768px) {
  .small-screen-block{
    display: block;
  }
  .bigger-screen-block{
    display: none;
  }
  .mat-drawer-container {
    position: static !important;
  }
  .main-header {
    font-size: 23px;
  }
  .api-basic-card mat-card-title {
    font-size: 19px !important;
  }
  .lookupModal{ 
    .modal-content {
    width: unset !important;
    }
  }
  .cancelPopup .modal-content {
    width: unset !important;
  }
  companies-details, my-api-keys, product-details-ext  {
    .demo-table-card tr th:first-child div {
      visibility: hidden !important;
    }
    .commondata-access-info {
      .pci-div {
        display: block;
      }
      table {
        width: 100%;
        tr, th, td {
          width: 100%;
        }
      }
    }
    table .view-more-btn {
      display: block !important;
      max-width: 100px;
    }
  }
  product-details-ext .commondata-access-info .merchant-hierarchy {
    table {
      td, th {
        width: 50% !important;
      }
    }
  }

  
  
}

.red-badge {
  color: $RedVariant3 !important;
  background-color: $RedVariant2 !important;
  mat-icon {
    color: $RedVariant3 !important;
  }
}
.green-badge {
  color: $GreenVariant1 !important;
  background-color: $GreenVariant4 !important;
  mat-icon {
    color: $GreenVariant1 !important;
  }
}
.orange-badge {
  color: $OrangeVaraint1 !important;
  background-color: $OrangeVariant2 !important;
  mat-icon {
    color: $OrangeVaraint1 !important;
  }
}
.green-status {
  background-color: $GreenVariant4 !important;
  mat-icon {
    color: $GreenVariant1 !important;
  }
}
.orange-status {
  background-color: $OrangeVariant2 !important;
  mat-icon {
    color: $YellowVariant1 !important;
  }
}
.default-status {
  background-color: $greyColorVariant1 !important;
  mat-icon {
    color: $greyColorVariant2 !important;
  }
}
.status-chip {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 3px 10px;
  display: inline-block;
  border-radius: 3px;
  pointer-events: none;
}
.status-chip-company {
  min-width: 68px !important;
  max-width: 83px !important;
  height: 22px !important;
  padding: 2px 8px 2px 8px !important;
  border-radius: 2px 0px 0px 0px !important;
  display: flex !important;
  align-items: center !important;
}

.mat-pseudo-checkbox-checked::after {
  color: $apiLightGray !important;
}
.required-error {
  color: $ErrorValidation !important;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}
.hightlight-error {
  border-color: $ErrorValidation !important;
}

.user-form {
  .user-form-details {
    margin-top: 15px;
    mat-grid-list {
      mat-grid-tile {
        padding-top: 20px !important;
        .mat-grid-tile-content {
          justify-content: left !important;
        }
      }
    }
    .user-details-container, .user-data-container {
      padding: 0 !important;
      height: 23rem;
      mat-hint {
        font: $FormLabelFont12400 !important;
        color: $fieldLabel !important;
      }
      mat-grid-tile {
        height: 110px;
      }
      .search-box-input-filter {
        .mat-form-field-infix {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
      .fis-icon-search {
        float: right;
        position: relative;
        top: -32px;
        right: 6px;
        font-size: 18px !important;
        margin-top: 4px;
        cursor: pointer;
      }
    }
    .user-role-container {
      padding: 0 !important;
      height: 19rem;
      width: 100%;
      mat-grid-tile {
        overflow: visible;
        width: 100% !important;
        mat-radio-group {
          width: 99%;
          > mat-label {
            font: $FormLabelFont14400;
            color: $fieldLabel;
          }
          mat-radio-button {
            border: $fisFieldBorder;
            margin-top: 10px;
            min-height: 120px;
            width: 100%;
            mat-label {
              font: $Button2Font;
            }
            .mat-radio-label {
              -webkit-user-select: none;
              user-select: none;
              cursor: pointer;
              display: inline-flex;
              align-items: unset !important;
              white-space: nowrap;
              vertical-align: middle;
              width: 96%;
              padding: 25px 25px 10px;
            }
            ul {
              padding-left: 20px;
              li {
                white-space: break-spaces;
                font: $FormLabelFont14400;
                color: $fieldLabel;
              }
            }
          }
        }
      }
    }

    .bottom-message {
      height: 30px !important;
      padding-top: 0 !important;
    }
  }
}

@media (max-width: 599.9px) {
  .user-form .user-form-details .user-details-container {
    height: 32rem;
  }
  .small-screen-company-name {
    margin-top: 115px !important;
  }
  .user-form
    .user-form-details
    .user-role-container
    mat-grid-tile
    mat-radio-group
    mat-radio-button
    .mat-radio-label {
    width: 92%;
  }
}
.user-form-details .mat-form-field {
  height: 40px !important;
}
.small-screen-company-name .mat-form-field-subscript-wrapper {
  margin-top: -14px !important;
}
.mat-paginator-page-size-select {
    margin-top: 17px;
    width: 40px;
    border: 1px solid #dedede;
    display: inline;
}
.mat-select-trigger {
  margin-top: 8px;
    padding: 8px;
}
.user-registration {
  width: 100%;
  display: block;
  overflow: auto;
  height: 100vh;
  .valign {
    width: 49%;
  }
  .container-content {
    width: 100%;
    height: calc(100vh - 70px);
    .image-container {
      background: $apiDarkBlue; 
      text-align: left;
      margin-right: auto;
      margin-left: auto;
      min-height: 110ch;
      display: flex;
      justify-content: center;
      align-items: center;
      .registration-steps {
        ul {
          list-style-type: none;
          padding: unset;
          li {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            height: 140px;
            .icon-div {
              display: flex;
              flex-direction: column;
              align-items: stretch;
            }
            mat-icon {
              font-size: 40px;
              font-weight: unset;
              color: $white !important;
              border-radius: 50%;
              border: 3px solid $white; 
              position: relative;
              top: 0;
              height: 80px;
              width: 80px;
              text-align: center;
              line-height: 80px;
            }
            .step-text {
              width: 200px;
              color: $white !important;
              margin-left: 30px;
              h3 {
                margin-top: unset;
                margin-bottom: 10px;
                font: $H3Font;
              }
              span {
                font: $FormLabelFont12400;
              }
            }
            .step-spacer {
              border-left: 3px solid #ffffff;
              height: 56px;
              margin-left: 40px;          
            }
          }
        }
      }
    }
    .form-container {
      padding:0px 10px;
      position: unset;
      .registration-form {
        align-items: center;
        display: flex;
        margin: auto;
        flex-direction: column;
        form{
          width: 73%;
          margin-bottom: 30px;
        }
        .button-container button {
          float: right;
          margin-right: 20px !important;
        }
        .user-info {
          h3 {
            margin: unset;
          }
        }
        .form-details {
          margin-top: 25px;
          .mat-form-field {
            width: 100%;
            margin-top: 15px;
          }
        }
      }
    }
  }
  .mat-form-field-underline {
    display: none !important;
  }
  .text-style {
    font: $H3Font;
    margin-top: 0px;
    a{
      color: $fisGreen;
    }
  }
  .expired-container {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    .expired-content {
      width: 360px;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      mat-icon {
        font-size: 30px;
        color: $YellowVariant1 !important;
      }
    }
  }
}
@media (max-width: 992px) {
  .user-registration{
    .image-bg {
      display: none;
    }
    .form-container.valign{
      width: 100%;
    }
  }
}

.small-screen-company-name .mat-form-field-subscript-wrapper{
  margin-top: -14px !important;
} 
@media screen and (min-width: 628px){
  .error-block {
    position: absolute;
    left: 10px;
    top: 65px;
  }
}
@media screen and (max-width: 628px){  
  .filter-search-container .mat-form-field-infix {
    height: 66px !important;
  }
  .mat-icon-button[fisStyle]:not([disabled]):hover, .mat-icon-button[fisStyle]:not([disabled]).cdk-keyboard-focused, .mat-icon-button[fisStyle]:not([disabled]).cdk-program-focused, [fisStyle] .mat-icon-button:not([disabled]):hover, [fisStyle] .mat-icon-button:not([disabled]).cdk-keyboard-focused, [fisStyle] .mat-icon-button:not([disabled]).cdk-program-focused {
    background:transparent !important;
  }
  .filter-search-container .mat-card-content .card-content {
    width: 100%;
  }
  .table-inside  {
    button {
      width: 100% !important; 
    }
    .mat-button-wrapper{
      display: flex;
      text-align: center !important;
      justify-content: center !important;
    }
  }
}
.mobile-tab-style .mat-tab-labels .mat-tab-label:nth-child(2),
.mobile-tab-style .mat-tab-labels .mat-tab-label:nth-child(3){
display: none;
}

.mobile-tab-style [fisStyle] .mat-icon-button:not([disabled]):hover {
  background: transparent;
  .mat-icon-button { 
    color: #012834;
  }
  .mat-tab-group[fisStyle] .mat-tab-labels .mat-tab-disabled{
    color: #012834;
  }
}
.valign{
  padding-right: 0;
  padding-left: 0;
  display: inline-block;
  float: none;
  vertical-align: middle;
  width: 50%;
  height: 100vh;
}
.width-50 {
  width: 50%;
}
.width-60 {
  width: 60%;
}
.width-20 {
  width: 20%;
}
.margin-left-20 {
  margin-left: 20px;
}
.mtminus-15 {
  margin-top: -15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.padding-top-10 {
  padding-top: 15px;
}
.label-style {
  font: $FormLabelFont12400;
  color: $fieldLabel;
}
.check-icon {
  display: flex;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid $fisGreen;
  align-items: center;
  margin: auto;
  mat-icon {
    font-size: 20px !important;
    color: $fisGreen !important;
  }
}
.check-icon-large {
  display: flex;
  justify-content: center;
  height: 115px;
  width: 115px;
  border-radius: 50%;
  border: 1px solid $fisGreen;
  align-items: center;
  margin: auto;
  mat-icon {
    font-size: 85px !important;
    color: $fisGreen !important;
  }
}

.mat-tab-label.mat-tab-disabled {
  color: #012834 !important;
}

.view-more-drop-down {
  margin: 2px;
  font-size: 16px !important;
}


/* Temporary Workaround:
 * changing the width of dropdown
 */
//  .cdk-overlay-connected-position-bounding-box {

//   .cdk-overlay-pane {
//     transform: none !important;
//     overflow-x: hidden;
//     box-sizing: content-box;
//     padding: 4px 4px 10px 5px;
//     margin-left: -5px;
//     margin-top: -2px;

//     .mat-select-panel {
//       margin-top: 27px;
//       background: #fff;
//       max-height: 300px;
//       min-width: 100% !important;
//     }
//   }
// }
@media screen and (max-width: 767px){ 
  .tabs-access {
    width: 94vw !important;
    overflow: auto;
  } 
}

@media screen and (max-width: 375px){  
  user-administration .mat-tab-group[fisStyle] .mat-tab-labels > .mat-tab-label{
    padding: 0px 20px !important;
  }
  .mat-stroked-button{
    min-width: auto !important;
  }
  // .mat-card>:first-child{
  //   margin-left: -15px;
  // }
  .table-inside button{
    max-width: 24px !important;
  } 
  body .view-more-btn{
    max-width: 90px !important;
  }

  .cdk-column-arrow { 
    max-width: 15px !important;
  }
  .mat-table[fisStyle] .mat-row .mat-cell{
    font-size: 10px !important;
  }
  body .status-chip{
    font-size: 9px !important;
  } 
  .company-head .link-div {
    float: unset !important;
  }
}

// app-api-keys {

//   .tab-container {
//     .mat-tab-header {
//       border-bottom: unset !important;
//       margin: auto;
//     }
//   }
//   .mat-paginator-container {
//     justify-content: center !important;
//     font-size: 14px;
//     font-weight: 400;
//     line-height: 20px;
//     font-family: $fontFamily;
//     .mat-paginator-page-size-select {
//       border: unset !important;
//     }
//     .mat-form-field-infix mat-select  {
//       margin-left: 5px;
//     }
//   }
//   // .mat-form-field-infix {
//   //   border: none !important;
//   //   mat-select {
//   //     padding: 3px 5px 10px;
//   //     border-radius: 4px;
//   //     margin: 2px 0;
//   //     height: 27px;
//   //     border:$fisFieldBorder;
//   //   }

//   //   .mat-select-arrow-wrapper {
//   //     margin-top: -2px;
//   //   }
//   // }
//   .mat-form-field-appearance-outline .mat-form-field-outline {
//     top: unset !important;
//   }
//   .status-sent{
//     color: $GreenVariant1 !important;
//   }
//   .status-revoked{
//     color: $OrangeVaraint1 !important;
//   }
//   .status-expired{
//     color: $PurpleVariant3 !important;
//   }
//   .align-icon {
//     vertical-align: middle;
//     padding-right: 5px;
//   }
// }
app-add-new-application {
 
   .mat-select-trigger {
    transition: none !important; /* Disables animation */
   }
  .mat-form-field-subscript-wrapper{
    display: none;
  }
  .custom-select .mat-select-value-text {
    display: none;
  }
  .tab-container {
    .mat-tab-header {
      border-bottom: unset !important;
      margin: auto;
    }
  }
  .mat-paginator-container {
    justify-content: center !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: $fontFamily;
    .mat-paginator-page-size-select {
      border: unset !important;
    }
    .mat-form-field-infix mat-select  {
      margin-left: 5px;
    }
  }
  .mat-form-field-infix {
    border: none !important;
    mat-select {
      padding: 3px 5px 10px;
      border-radius: 4px;
      margin: 2px 0;
      height: 27px;
      border:$fisFieldBorder;
    }
    mat-select.custom-select {
      padding: 3px 5px 10px;
      border-radius: 4px;
      margin: 2px 0;
      height: 27px;
      border: none;
      position: absolute;
      top: 2px;
      height: 0;
      padding: 0;
      margin: 0;
      right: -10px; 
      float: right; 
    }
    mat-select.mat-select-empty{
      top: -9px;
    }
    

    .mat-select-arrow-wrapper {
      margin-top: 5px;
      z-index: 9;
      position: relative;
    }
    
  }
  .add-new-app-cont .mat-select-arrow { 
    margin: 0 4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    margin: 0 4px;
    position: relative;
}
  .add-new-app-cont .mat-select-arrow::before {
    content: '';
    position: absolute;
    top: -7px;
    left: -6px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
}  
  .add-new-app-cont .mat-select-trigger{
    margin-top: 0 !important;
    padding: 8px 8px 8px 0;
  }
  .add-new-app-cont .custom-select .mat-select-placeholder {
    position: relative;
      top: -3px
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: unset !important;
  }
  .status-sent{
    color: $GreenVariant1 !important;
  }
  .status-revoked{
    color: $OrangeVaraint1 !important;
  }
  .status-expired{
    color: $PurpleVariant3 !important;
  }
  .align-icon {
    vertical-align: middle;
    padding-right: 5px;
  }
  .mat-form-field-underline { 
    height: 0 !important; 
  }
}
app-invite-user {
  .tab-container {
    .mat-tab-header {
      border-bottom: unset !important;
      margin: auto;
    }
  }
  .mat-paginator-container {
    justify-content: center !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: $fontFamily;
    .mat-paginator-page-size-select {
      border: unset !important;
    }
    .mat-form-field-infix mat-select  {
      margin-left: 5px;
    }
  }
  .mat-form-field-infix {
    border: none !important;
    mat-select {
      padding: 3px 5px 10px;
      border-radius: 4px;
      margin: 2px 0;
      height: 27px;
      border:$fisFieldBorder;
    }

    .mat-select-arrow-wrapper {
      margin-top: -2px;
    }
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: unset !important;
  }
  .status-sent{
    color: $GreenVariant1 !important;
  }
  .status-revoked{
    color: $OrangeVaraint1 !important;
  }
  .status-expired{
    color: $PurpleVariant3 !important;
  }
  .align-icon {
    vertical-align: middle;
    padding-right: 5px;
  }
}

user-administration {
  .tab-container {
    .mat-tab-header {
      border-bottom: unset !important;
      margin: auto;
    } 
  }
  .mat-paginator-container {
    justify-content: center !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: $fontFamily;
    .mat-paginator-page-size-select {
      border: unset !important;
    }
    .mat-form-field-infix mat-select  {
      margin-left: 5px;
    }
  }
  .mat-form-field-infix {
    border: none !important;
    mat-select {
      padding: 3px 5px 10px;
      border-radius: 4px;
      margin: 2px 0;
      height: 27px;
      border:$fisFieldBorder;
    }

    .mat-select-arrow-wrapper {
      margin-top: -2px;
    }
  }
  .form-container {
    .form-part {
      .mat-form-field-infix {
        mat-select {
          padding: 3px 0px 10px;
        }
      }
    }
  }
  .form-details {
    padding-top: 24px;
    .mat-form-field-appearance-legacy .mat-form-field-wrapper  {
      padding-top: 24px !important;
    }
    .mat-form-field-label-wrapper {
      top: -8px;
      padding-top: unset !important;
    }
    .mat-hint {
      margin-top: -7px !important;
    }
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: unset !important;
  }
  .status-sent{
    color: $GreenVariant1 !important;
  }
  .status-revoked{
    color: $OrangeVaraint1 !important;
  }
  .status-expired{
    color: $PurpleVariant3 !important;
  }
  .align-icon {
    vertical-align: middle;
    padding-right: 5px;
  }
}
.profile-form .mat-form-field-underline {
  display: none;
}
.phone-reg {
  .mat-form-field-label-wrapper {
    padding-top: unset !important;
  }
 .mat-form-field-label-wrapper::after {
    content: "Optional";
    width: 100px;
    right: 0;
    position: absolute;
    text-align: right;
    font: $FormLabelFont !important;
    color: $fieldLabel !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .filter-search-container .mat-card-content .card-content {
    width: 100% !important;
  }
}
@media screen and (max-width: 525px) {
  .lookupModal {
    .mat-paginator-range-actions {
      display: block;
      margin-top: 10px;
    }
  } 
  product-details, product-details-ext {
    .summary-tab-stepper {
      tr {
        th {
          width: unset;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .desktop-buttons {
    display: none !important;
  }
  .mobile-buttons {
    display: block !important;
    width: 100% !important;
    margin-top: 20px
  }
}
.form-container {
  width: 98%;
  z-index: 999;
  top: 0;
  position: fixed;
  right: 0;

  .modal-part {
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .form-part {
    width: 493px;
    height: 100vh;
    float: right;
    position: relative;
    background: #ffffff;
    padding: 32px;
    overflow-y: auto;
    scrollbar-width: thin;
    form {
      h3 {
        margin: unset;
      }
    }
  }

  .filterbox-close {
    float: right;
    position: relative;
    top: -11px;
    left: 16px;
    mat-icon {
      font-size: 20px !important;
      color: $formCloseIconGrey !important;
    }
  }
  .help-text {
    font-size: 14px;
    line-height: 1.5rem;
    padding-right: 5px;
  }
  .submit-notification {
    padding-right: 5px;
  }
  .button-container {
    padding-bottom: 45px;
    margin-top: 20px;
    button {
      width: 47%;
    }
  }
}
.modal-details-container {
  width: 750px;
  z-index: 999;
  top: 71px;
  position: fixed;
  right: 0;
  .details-part {
    width: 100%;
    height: 100vh;
    float: right;
    position: relative;
    background: #ffffff;
    padding: 32px;
    overflow-y: auto;
    scrollbar-width: thin;
    .info-container {
      h3{
        margin: unset;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        color: $fisDarkTeal;
      }
      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: $descriptionFontGreyDark;
      }
      h4 {
        font: $H3Font;
        margin: unset;
        margin-top: 25px;
        margin-bottom: 4px;
      }
      .sub-description {
        font: $FormLabelFont;
      }
      .mat-tab-group.mat-tab-group-dynamic-height .mat-tab-body.mat-tab-body-active {
        overflow-y: auto !important;
        scrollbar-width: thin;
      }
    }
    .tab-container {
      margin-top: 35px;
      height: calc(100% - 300px);
    }
    button {
      width: 100%;
    }
  }

  .filterbox-close {
    float: right;
    position: relative;
    top: -11px;
    left: 16px;
    mat-icon {
      font-size: 20px !important;
      color: $formCloseIconGrey !important;
    }
  }
  .help-text {
    font-size: 14px;
    line-height: 1.5rem;
    padding-right: 5px;
  }
  .submit-notification {
    padding-right: 5px;
  }
  .button-container {
    padding-bottom: 45px;
    margin-top: 20px;
    button {
      width: 47%;
    }
  }
}

@media screen and (max-width: 768px){ 
  .invite-filter .mat-card-content .card-content{
      width: 100%;
  }
  .invite-filter .mat-form-field-infix mat-select{
      padding-left: 0;
      padding-right: 0;
  }
  .lookupModal .mat-select{
    width: 97% !important;
  }
  .search-dropdown-invite-filter{
    width:40% !important;
  }
  .search-invite-filter{
    width: 59% !important;
    margin-left: -9px;
    margin-bottom: 26px; 
  }
  .filter-search-container .mat-card-content .card-content {
      width: 100% !important;
      .mat-form-field {
        width: 98% !important;
        margin-top: 20px;
        margin-left: 0px;
      }
  }
  
  .lookupModal {
    padding-top: unset !important;
    // .mat-paginator-page-size-label {
    //   display: none;
    // }
    // .mat-paginator-container .mat-paginator-page-size-select {
    //   display: none;
    // }
  }
  .lookupModal .modal-content .demo-table-card {
    height: 200px !important;
  }
  .mobile-tab-style .mat-tab-labels .mat-tab-label:nth-child(3) {
    display: block;
  }
  .form-container .form-part {
    padding-left: 10px;
    width: 97%;
    height: 100%;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    overflow: auto;
}
}
@media screen and (max-width: 320px) {
  .confirm-button-box {
    float: unset !important;
    button {
      margin-left: unset !important;
    }
  }
}
@media screen and (min-width: 1440px) {
   my-api-keys, lookup-modal  {
    table {
      table-layout: fixed;
    }
    >.demo-table-card tr th:first-child, >.demo-table-card tr td:first-child:not(.blankData) {
      display: none !important;
    }
  }
  // lookup-modal {
  //   .demo-table-card tr th:first-child, .demo-table-card tr td:first-child {
  //     display: block !important;
  //   }
  // }
  .api-key-info {
    table {
      table-layout: fixed;
    }
  }
}
@media screen and (max-width: 1439px) {
  .api-key-info, .product-info {
    table {
      table-layout: unset !important;
    }
  }
}
/* For touch devices */
@media (hover: none) {
  .mat-stroked-button[fisStyle].mat-primary:not([disabled]):hover,
  .mat-stroked-button[fisStyle]:not([disabled]):hover {
    background-color: transparent !important;
  }
  .mat-raised-button[fisStyle].mat-primary:not([disabled]):hover{
    background-color: #49498C !important;
  }
}
.read-only {
  border-color: #dfdfdf !important;
  background-color: #eeeeee !important;
}
 
.company-head {
  h2{
    font: $H1Font;
    // margin-top: 50px;
  }
  .subheading {
    font: $font16Source !important;
    font-weight: bold !important;
    margin-bottom: 10px;
  }
  .subheading {
    font: $font16Source;
  }
  .link-div{
    float: right;
    max-width: 230px;
  }
  a{
    font-weight: 800 !important;
    font-size: 1rem;
    line-height: 24px;
    color:$fisBlack; 
    svg{
      fill: $fisGreen;
    }
    &:hover{
      color: $fisGreen;
      svg {
        fill: $fisGreen;
        margin-left: 12px;
      }
    } 
    svg{
      transition: margin-left .25s;
      margin: auto 0 auto 6px;
      width: 30px;
    }
  }
}

.companyTables table {
  box-shadow: none;
  th.mat-header-cell:first-of-type{
    padding-left: unset !important;
  }
  th {
    font-weight: bold;
  }

  th,
  td {
    font-size: 14px;
  }
  .loading {
    position: absolute; 
    display: block;
    left: 0;
    padding-top: 5px;
    margin: auto;
    width: 100%;
    .spinner {
      margin: auto; 
      border: 2px solid #49498C ;
      border-bottom-color: transparent; 
      border-radius: 50%;
      width: 20px;
      height: 20px;
      animation: spin 2s linear infinite;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
}
app-company-webhooks-events{ 
  .companyTables {
    margin-bottom: 50px;    
  }  
}
app-company-products {
  .demo-table-card tr th:first-child div {
    visibility: hidden !important;
  }
}
.companyTables {

  .mat-expansion-panel-content{
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
  .hide-first-row{
    display: none;
  }
  table tbody {
    display: block;
    max-height: 240px;
    overflow-y: auto;
    scrollbar-width: thin;
  } 
  table thead, table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
#ot-sdk-btn-floating.ot-floating-button {
  bottom: 45px !important;
}
.prim-support {
  width:auto; 
  font-family: 'Source Sans Pro', Arial, sans-serif;
  height: 36px; 
  border-radius: 24px;
  border: 3px solid #49498C;
  background-color: #49498C;
  color: #012834; 
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center; 
}

.prim-support:hover {
  height: 36px;
  width:auto;
  border: 3px solid #49498C;
  border-radius: 24px;
  background: $white;
  color: #012834; 
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
}
.tooltip {
  position: relative;
  display: inline;
  cursor: pointer;
  mat-icon {
    width: 12px !important;
    height: 12px !important;
    color: $iconGreyColor !important;
    font-size: 12px !important;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: $iconGreyColor;
  color: $white;
  text-align: left;
  border-radius: 6px;
  padding: 5px 7px;
  font: $FormLabelFont12400;
  top: 20px;
  left: 0px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.header-border {
  border-bottom-color: #c1c1c1;
  color: #012834;
  border-bottom-style: solid;
  border-bottom-width: 0.0625rem;
  padding: 0.3125rem 0.9375rem;
}
.apply-border {
  border-bottom-width: 0.8px; 
}
.no-border {
  border-bottom: unset !important;
}
@media screen and (max-width: 500px){   
  .button-container {
    button {
      width: 100% !important;
      margin-top: 10px;
    }
  }
}
.company-list-section {
  .demo-table-card {
    .mat-table .mat-header-row .mat-header-cell {
      border-bottom: 2px solid $fisDarkTeal !important;
      border-bottom-width: 2px !important;
      font-size: 14px;
      font-weight: 700;
      line-height: 17.6px;

    }
    .mat-table .mat-row .mat-cell {
      min-width: 156px !important;
      font-size: 14px; 
      font-weight: 400;
      line-height: 17.6px;
      max-width: 200px;
      word-wrap: break-word;
    }
    .no-max-width{
      max-width: unset !important;
      word-wrap: unset !important;
    }
    .mat-table .mat-row {
      .mat-cell:nth-child(1) {
        font-weight: bold;
        text-decoration: underline;
        font-size: 14px;
        font-weight: 600;
        line-height: 17.6px;
      }
    }
    .mat-table .mat-row {
      .mat-cell:last-child {
        width: 100%;
        text-align: right;
      }
    }
  }
  .demo-table-card tr th:first-child div {
    visibility: unset;
  }
 
  .mat-paginator-container {
    display: inline-block;   
    .mat-paginator-page-size {
      float: right;
      margin-right: unset !important
    }
    .mat-icon-button {
      height: 18px;
      width: 18px;
    }
  }
  .mat-paginator {
    // override material paginator page switch
    .mat-paginator-range-label {
        order: 3 !important;
        font-size: 14px !important;
        font-weight: 400;
        line-height: 17.6px;
    }
    .mat-paginator-page-size-label {
      font-size: 12px !important;
        font-weight: 400;
        line-height: 15.8px;
    }
    .mat-paginator-navigation-first {
      order: 1 !important;
    }
    .mat-paginator-navigation-previous {
        order: 2 !important;
    }
    .mat-paginator-navigation-next {
        order: 4 !important;
    }
    .mat-paginator-navigation-last {
      order: 5 !important;
    }
    .mat-paginator-range-actions {
      justify-content: center !important;
    }
  }
}

app-company-list {
  .filter-section {
    .filter-field {
      min-width: 250px;
      height: 42px;
      border-radius: 4px;
      border: 1px solid $fieldBorderLightCyan;
      justify-content: space-between;
      margin-right: 8px;
      color: $fisBlack;
      align-content: center;
      input {
        border: unset !important;
        margin-top: unset !important;
        padding: unset !important;
      }
      input::placeholder, .mat-select-placeholder {
        font: $FormLabelFont !important;
        color: $formCloseIconGrey !important;
        opacity: 1; /* Firefox */
      }
      
      .search-icon-position {
        float: left;
        position: relative;
        font-size: 15px;
      }
      .arrow-icon-position {
        float: right;
        position: relative;
        font-size: 18px;
      }
    }
    button {
      min-width: 115px;
    }
    .filter-field.mat-focused{
      border-color: $fisDarkTeal !important;
    }
    .mat-form-field-underline {
      display: none !important;
    }
    .company-name-select {
      .mat-form-field-infix {
        >div {
          display: flex;
          width: 100%;
          align-items: center;
        }
      }
    }
  }
 
  .hide-arrow {
    .mat-select-arrow-wrapper {
      display: none !important;
    }
  }
}
// app-api-keys {
//   .filter-section {
//     .filter-field {
//       max-width: 309px;
//       height: 42px;
//       border-radius: 4px;
//       border: 1px solid $fieldBorderLightCyan;
//       justify-content: space-between;
//       margin-right: 8px;
//       color: $fisBlack;
//       align-content: center;
//       input {
//         border: unset !important;
//         margin-top: unset !important;
//         padding: unset !important;
//       }
//       input::placeholder, .mat-select-placeholder {
//         font: $FormLabelFont !important;
//         color: $formCloseIconGrey !important;
//         opacity: 1; /* Firefox */
//       }
      
//       .search-icon-position {
//         float: left;
//         position: relative;
//         font-size: 15px;
//       }
//       .arrow-icon-position {
//         float: right;
//         position: relative;
//         font-size: 18px;
//       }
//     }
//     button {
//       min-width: 98px;
//     }
//     .filter-field.mat-focused{
//       border-color: $fisDarkTeal !important;
//     }
//     .mat-form-field-underline {
//       display: none !important;
//     }
//     .company-name-select {
//       .mat-form-field-infix {
//         >div {
//           display: flex;
//           width: 100%;
//           align-items: center;
//         }
//       }
//     }
//   }
//   .main-header {
//     font-weight: 700 !important;
//   }
//   .hide-arrow {
//     .mat-select-arrow-wrapper {
//       display: none !important;
//     }
//   }
  
// }
.filter-field {
  .mat-select-arrow-wrapper {
    background-image: url("../src/assets/icons/Chevron_Down.svg") !important;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    width: 16px;
    height: 16px;
  }
  .mat-select-arrow {
    display: none;
  }
}
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  .mat-autocomplete-panel, .mat-select-panel-wrap .mat-select-panel  {
    min-width: 100% !important;
    width: 250px !important;
    max-height: 240px !important;
    padding: 0px !important;
    border-radius: 4px !important;
    border: 1px solid $fieldBorderLightCyan !important;
    scrollbar-width: thin !important;
    .mat-option {
      // width: 229px !important;
      height: 48px !important;
      // padding: 12px !important;
      .mat-option-text {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left !important;
      }
    }
  }
}
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-select-panel-wrap .mat-select-panel.wp-select-application-invite {
  min-width: 102% !important;
}
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-select-panel-wrap .mat-select-panel.wp-select-subscriber-invite {
  min-width: 102% !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border: none !important;
}
@media screen and (max-width: 1440px) {
  app-company-list {
    .filter-section {
      .filter-field {
        min-width: 190px !important;
      }
    }
  }
}
app-breadcrumb {
  mat-icon {
    transform: rotate(270deg) !important;
    font-size: 18px !important;
  }
}
.details-container .demo-table-card .mat-table .mat-header-row .mat-header-cell{
  padding: 8px;
}

.details-container .mat-tab-body-content{
  margin-top: 16px;
}
.tabs-access-application-details {
  width: 100vw;
  padding: 20px 0 0 0;
  height: 100%;
  overflow-y: auto;
  display: block;
  th {
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 2px solid #012834;
    padding: 8px 16px 8px 8px;
    min-width: 160px;
  }
  td {
    padding: 8px 16px 8px 8px;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 1px solid #c3c3c3;
    word-break: break-word;
  }
}

companies-details { 
  .mat-form-field-infix {
    border: none !important;
    mat-select {
      padding: 3px 5px 10px;
      border-radius: 4px;
      margin: 2px 0;
      height: 27px;
      border:$fisFieldBorder;
    } 
    .mat-select-arrow-wrapper {
      margin-top: -2px;
    }
  } 
}

.stroked-button-no-border {
  border: unset !important;
  background: unset;
  font: $ButtonSearchSectionFont;
  cursor: pointer;
}

app-application-details  { 
  .mat-form-field-infix {
    border: none !important;
    mat-select {
      padding: 3px 5px 10px;
      border-radius: 4px;
      margin: 2px 0;
      height: 27px;
      border:$fisFieldBorder;
    } 
    .mat-select-arrow-wrapper {
      margin-top: -2px;
    }
  } 
}

//table loading
.table-loading {
  display: flex;
  justify-content: center;
  align-items: start;
  background: white;
}
 
.tab-loading {
  .spinner {
  margin: auto;
  border: 2px solid #49498C;
  border-bottom-color: transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  }
}
 
@keyframes spin {
  0% {
  transform: rotate(0deg);
  }
 
  100% {
  transform: rotate(360deg);
  }
}
#dynamic-notification-success {
  margin-bottom: 10px;
}
.cookie-link-footer {
  font: $FormLabelFontH3;
  color: $fisDarkTeal;
  padding-left: 16px;
}
@media screen and (min-width: 2559px) {
  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-select-panel-wrap .mat-select-panel.wp-select-application-invite {
    min-width: 101% !important;
  }
  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-select-panel-wrap .mat-select-panel.wp-select-subscriber-invite {
    min-width: 101% !important;
  }
}

/* wp new design style */

@mixin font-source-sans-3($weight: 400, $size: 16px) {
  font-family: $fontFamilySourceSans3;
  font-weight: $weight;
  font-size: $size; 
}

.wp-secondary-btn {
  border:1.5px solid $wpdeepblue;
  border-radius: 8px;
  color: $brandcolor;
  background: $white;
  height: 36px;
  padding: 0 12px;
  cursor: pointer;
  @include font-source-sans-3(600, 16px);
  &:hover{
    background: $wpdeepbluefade;
  }
}

.wp-primary-btn {
  border:1.5px solid $wpdeepblue;
  border-radius: 8px;
  color: $white;
  background: $brandcolor;
  height: 36px;
  padding: 0 12px;
  @include font-source-sans-3(600, 16px);
  cursor: pointer;
  font-weight: 600;

  &:hover{
    background: $wpdeepblue;
  }
}

.wp-btn-disable{
  background: $wpprimary-disable;
  border-color:$wpprimary-disable;
  &:hover{
    background: $wpprimary-disable;
  }
}

/* file upload */

/* Hide the native file input */

.wp-custom-btn-pos {
  float: left;
  margin-right: 10px;
}

.hidden-file-input {
  display: none;
}
.wp-btn-height{
  display: inline-block; 
  height: 34px;
  line-height: 34px;
}
 .wp-custom-plus-icon{
  color: $white !important;
  display: inline-flex !important;
  line-height: 13px;
  padding: 0;
  height: auto !important;
 }

 .file-info {
  margin-top: 6px;
  float: left;
}

.wp-file-name{
  margin-top: 2px;
    float: left;
}
button.delete-file-btn {
  background: none;
  border: none;
  float: right;
  cursor: pointer;
  margin-top: 0;
}

.mat-ink-bar {
  background-color:$brandcolor !important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle{
  border-color: $brandcolor !important;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color:$brandcolor !important;
}

.mat-radio-ripple .mat-ripple-element {
  background-color: transparent !important;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: $brandcolor !important;
}

.mat-checkbox.mat-primary .mat-checkbox-mixedmark {
  background-color: $white !important;
}

.mat-checkbox.mat-primary .mat-checkbox-checkmark-path {
  stroke: $white !important;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $brandcolor !important;
}

.mat-pseudo-checkbox-checked::after {
  border-left: 2px solid $white !important;
}

.mat-pseudo-checkbox::after {
  border-bottom: 2px solid $white !important;
}

.mat-datepicker-toggle-active {
  color: $brandcolor !important;
}
.mat-calendar.fis-style .mat-calendar-header .mat-calendar-controls .mat-icon-button, .mat-calendar .mat-calendar-header .mat-calendar-controls .mat-icon-button {
  color: $brandcolor !important;
}

.mat-calendar.fis-style .mat-calendar-body-cell-content.mat-calendar-body-today, .mat-calendar .mat-calendar-body-cell-content.mat-calendar-body-today {
 border-color: $brandcolor !important;
}

.mat-calendar-previous-button.mat-icon-button.mat-button-base:active {
  background-color: $brandcolor !important;
}

.mat-calendar.fis-style .mat-calendar-header .mat-calendar-controls .mat-icon-button:not(.mat-button-disabled):hover .mat-button-focus-overlay, .mat-calendar.fis-style .mat-calendar-header .mat-calendar-controls .mat-icon-button:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay, .mat-calendar.fis-style .mat-calendar-header .mat-calendar-controls .mat-icon-button:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay, .mat-calendar .mat-calendar-header .mat-calendar-controls .mat-icon-button:not(.mat-button-disabled):hover .mat-button-focus-overlay, .mat-calendar .mat-calendar-header .mat-calendar-controls .mat-icon-button:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay, .mat-calendar .mat-calendar-header .mat-calendar-controls .mat-icon-button:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay{
  background-color: $brandcolor;

} 

.mat-calendar .mat-calendar-header .mat-calendar-controls .mat-icon-button:not(.mat-button-disabled):hover .mat-button-focus-overlay{
  background-color: $brandcolor !important;
}

.mat-checkbox-ripple .mat-ripple-element {
  background-color: $brandcolor !important;  
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: $brandcolor !important;
}
